.header {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 20;
    box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
    height: 95px;
    background-color: $color-white;
    
    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        width: 170px;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-main;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        z-index: 3;

        &:before {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            background-color: $color-white;
            width: 100%;
            height: 0;
            z-index: -1;
            transition: all $duration ease-in-out;
        }

        svg {
            width: 50px;
            height: 60px;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &.overlay {
            height: 95px;

            svg {
                fill: $color-main;
            }
            &:before {
                height: 100%;
            }
        }
    }

    &__title {
        font-size: 0;
        list-style: 0;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    align-items: center;
    gap: 5px;

    #accessconfig {
        max-height: 40px;
    }

    &__item {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;

        svg {
            fill: $color-dark;
            width: 100%;
            height: 100%;
        }

        &:hover, &:focus {
            background-color: transparent;
            border: none;

            svg {
                fill: $color-main;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__link {
            width: 150px;
            height: 110px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        &__container {
            height: 100%;
        }

        &__link {
            width: 170px;
            height: 110px;
            z-index: 2;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        height: 70px;

        &__link {
            width: 90px;
            height: 90px;

            svg {
                width: 42px;
                height: 50px;
            }
        }
    }
}
