//=================================================================================================
// Wrapper for listing of cards in archive templates
//================================================================================================= 
.listing-cards {
    padding-top: 1.5*$gl-gutter;
    transition: .5s ease-in-out;
    margin: 0;

    article {
        padding: 0 15px 15px;
    }

    &[data-cpt="contacts"] {
        article {
            padding: 0 0px 15px;
        }
    }
}
