//=================================================================================================
// Home page
//=================================================================================================

// Usage : @extend %homeTitle;
%homeTitle {
    font-family: $font-family--heading;
    font-weight: 700;
    font-size: toRem(50);
    margin: 0;
    padding: 0;

    &:before {
        display: none;
    }
}

// Usage : @extend %homeTitleSmall;
%homeTitleSmall {
    font-family: $font-family--heading;
    font-weight: 600;
    font-size: toRem(25);
    margin: 0;
    padding: 0;

    &:before {
        display: none;
    }
}

// Usage : @extend %homeDescription;
%homeDescription {
    font-family: $font-family--heading;
    font-weight: 200;
    font-size: toRem(22);
    text-transform: uppercase;
}

//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

//Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered container
//=================================================================================================

//Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    %container {
    max-width: $medium;
    padding: 0 ($gl-gutter-md * 0.5);
    margin: 0 auto;
    }
}


// 960
@media screen and (max-width: $medium) {
    %container {
        max-width: $small;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }
}


// 600
@media screen and (max-width: $small) {
    %container {
        max-width: $x-small;
        padding: 0 ($gl-gutter-xs * 0.5);
        margin: 0 auto;
    }
}
