//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        height: 460px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(0deg, rgba(17, 17, 17, 0.3), rgba(17, 17, 17, 0.3));
                z-index: 1;
            }

            .ic-plus {
                position: absolute;
                z-index: 1;
                bottom: 30px;
                left: calc(50% + 545px);
                transform: scale(1);
                transition: all $duration ease-in-out;
            }

            &--link {
                &:hover, &:focus {
                    .ic-plus {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 700px;
            height: 150px;
            fill: $color-white;
            z-index: 1;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            gap: 10px;
            left: 50%;
            bottom: 30px;
            z-index: 2;
            width: auto;
            transform: translateX(-50%);
    
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin: 0;
                transition: .25s;
                background-color: $color-white;
                border-radius: 5px;
                opacity: 1;
    
                &:hover, &:focus {
                    background-color: $color-main;
                }
    
                &-active {
                    background-color: $color-main;
                }
            }
        }
    
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    padding: 80px 0 100px;
    overflow: hidden;

    &__blocs {
        display: flex;
        justify-content: space-between;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 500px;
            height: 475px;
            right: -422px;
            top: -85px;
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_ar.svg);
            background-repeat: no-repeat;
            z-index: 1;
            background-size: 500px;
        }
    }

    &__bloc {
        width: 120px;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: 400;
        font-size: toRem(16);
        line-height: 20px;
        text-transform: uppercase;
        position: relative;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            width: 70px;
            height: 70px;
        }

        p {
            display: flex;
            flex-direction: column;
            gap: 0;
            max-height: 40px;

            span {
                font-style: italic;
                font-weight: 700;
            }
        }

        &:before {
            position: absolute;
            content: "";
            width: 25px;
            height: 25px;
            border-radius: 50%;
            z-index: -1;
            transition: all $duration ease-in-out;
        }

        &:nth-child(1) {
            &:before {
                top: -5px;
                left: 45px;
                background-color: $color-fourth;
            }
        }

        &:nth-child(2) {
            &:before {
                top: 40px;
                left: -10px;
                background-color: $color-third;
            }
        }

        &:nth-child(3) {
            &:before {
                top: 45px;
                left: 48px;
                background-color: $color-main;
            }
        }

        &:nth-child(4) {
            &:before {
                top: -5px;
                left: -10px;
                background-color: $color-fourth;
            }
        }

        &:nth-child(5) {
            &:before {
                left: 30px;
                top: -10px;
                background-color: $color-gray;
            }
        }

        &:nth-child(6) {
            &:before {
                top: 30px;
                left: 55px;
                background-color: $color-second;
            }
        }

        &:hover, &:focus {
            &:before {
                transform: scale(1.5);
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 865px;
            height: 605px;
            left: -450px;
            top: 33px;
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_actus_desktop.svg);
            background-repeat: no-repeat;
            z-index: -1;
            background-size: 865px;
        }
    }

    &__title {
        @extend %homeTitle;
    }

    &__description {
        @extend %homeDescription;
        margin-top: -5px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__swiper {
        width: 1170px;
    }

    &__item {
        &--big {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        &--small {
            width: 370px!important;
        }

        &:hover, &:focus {
            .news__image img {
                transform: scale(1.075);
            }
        }
    }

    &__info {
        max-width: 300px;
        margin-bottom: 25px;
        color: $color-white;
    }

    &__titlep {
        @extend %homeTitleSmall;

        &--big {
            margin: 25px 0 15px;
        }

        &--small {
            font-size: toRem(20);
            margin-top: 10px;
            max-width: 370px;
        }
    }

    &__image {
        overflow: hidden;
        margin: 0;

        &--big {
            width: 770px;
            height: 400px;
        }

        &--small {
            width: 370px;
            height: 180px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: all 1s ease-in-out;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
        background-position: center;

        &--big {
            width: 770px;
            height: 400px;
        }

        &--small {
            width: 370px;
            height: 180px;
        }
    }

    &__navigation {
        display: flex;
        gap: 360px;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            width: 40px!important;
            height: 40px!important;
            position: relative!important;
            top: unset!important;
            left: unset!important;
            right: unset!important;
            bottom: unset!important;
            margin-top: 0!important;
            background-color: $color-main;
            border: 1px solid $color-main;
            border-radius: 50%;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            

            &:after {
                display: none;
            }

            svg {
                fill: $color-white;
                height: 100%;
                width: 100%;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: transparent;

                svg {
                    fill: $color-main;
                }
            }
        }
    }

    &__bottom {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 52px;
    }

    &__button {
        position: absolute;
        width: 300px;
        margin: 0;
        padding: 10.5px 0;
    }
}

//======================================================================================================
// Agenda
//======================================================================================================

.home-events {
    margin-top: 75px;

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 590px;
            height: 595px;
            right: -395px;
            top: -242px;
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_agenda.svg);
            background-repeat: no-repeat;
            z-index: -1;
            background-size: 590px;
        }
    }

    &__header {
        margin-bottom: 30px;
    }

    &__title {
        @extend %homeTitle;
    }

    &__description {
        @extend %homeDescription;
    }

    &__swiper {
        margin-top: 30px;
        padding: 15px!important;
        margin: -15px!important;
    }

    &__link {
        width: 270px!important;
        margin-right: 30px;
        min-height: 305px;
        padding: 48px 30px 30px;
        box-sizing: border-box;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.08);
        background-color: $color-white;

        svg {
            width: 10px;
            height: 10px;
            position: relative;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        &:before {
            position: absolute;
            content: "";
            height: 40px;
            width: 40px;
            left: 30px;
            top: 42px;
            mask-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/Pictos/fond_ic_plus.svg);
            mask-repeat: no-repeat;
            background-color: $color-third;
            z-index: 0;
            transform: scale(0);
            transition: all $duration ease-in-out;
        }    

        &:hover, &:focus {
            box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.03);
            transition: all $duration ease-in-out;

            &:before {
                transform: scale(1);
            }

            svg {
                fill: $color-white;
                margin-left: 17px;
            }
        }
    }

    &__title--small {
        @extend %homeTitleSmall;
        margin: 25px 0;
        height: 90px;
    }

    &__date {
        font-family: $font-family--heading;
        font-weight: 200;
        font-size: toRem(22);
        text-transform: uppercase;
        display: flex;
    }

    &__bottom {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 47px;
    }

    &__buttons {
        position: absolute;
        display: flex;
        flex-direction: row-reverse;
        gap: 30px;
    }

    &__button {
        width: 270px;
        margin: 0;
        padding: 10.5px 0;

        &--second {
            background-color: transparent;
            color: $color-main;

            &:hover, &:focus {
                background-color: $color-main;
                color: $color-white;
            }
        }
    }

    &__navigation {
        display: flex;
        gap: 630px;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            width: 40px!important;
            height: 40px!important;
            position: relative!important;
            top: unset!important;
            left: unset!important;
            right: unset!important;
            bottom: unset!important;
            margin-top: 0!important;
            background-color: $color-main;
            border: 1px solid $color-main;
            border-radius: 50%;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            

            &:after {
                display: none;
            }

            svg {
                fill: $color-white;
                height: 100%;
                width: 100%;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: transparent;

                svg {
                    fill: $color-main;
                }
            }
        }
    }
}

//======================================================================================================
// Réseaux sociaux - Social Wall
//======================================================================================================

.social {
    margin-top: 185px;

    &__container {
        display: flex;
        justify-content: space-between;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 600px;
            height: 635px;
            left: -400px;
            top: -200px;
            mask-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_rs_1.svg);
            background-color: $color-third;
            mask-repeat: no-repeat;
            z-index: -1;
            mask-size: 600px;
        }

        &:after {
            position: absolute;
            content: "";
            width: 785px;
            height: 730px;
            right: -400px;
            top: -260px;
            mask-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_rs_2.svg);
            background-color: $color-gray;
            mask-repeat: no-repeat;
            z-index: -1;
            mask-size: 785px;
        }
    }
    
    &__content {
        width: 260px;
        color: $color-white
    }

    &__title {
        @extend %homeTitle;
    }

    &__description {
        @extend %homeDescription;
    }

    &__intro {
        font-size: toRem(16);
        margin: 25px 0 30px;
    }
    
    &__wall {
        z-index: 9;
        width: 920px;
        height: 270px;
    }

    &__buttons {
        display: flex;
        gap: 15px;
    }

    &__button {
        background-color: $color-white;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        transition: all $duration ease-in-out;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            transform: scale(1.1);
        }
    }

    #ff-stream-1 {
        padding: 0;
        height: 100%;
        background-color: $color-bg--transparent;
        overflow: hidden;

        .ff-loadmore-wrapper, .ff-header, .ff-item-meta {
            display: none!important;
        }

        .ff-stream-wrapper {
            width: 920px;
            z-index: 9!important;
            padding: 0;
            height: 100%!important;
            margin: 0 0 0 -35px!important;

            .ff-item {
                width: 270px!important;
                height: 270px!important;

                .picture-item__inner {
                    box-shadow: unset;
                    background-color: $color-dark;
                }

                .ff-item-cont {
                    position: relative;
                    height: 270px!important;
                    color: $color-white!important;

                    .ff-img-holder {
                        height: 270px!important;
                        z-index: 0;

                        & img {
                            min-height: unset !important;
                        }
                    }

                    .ff-content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: 0;
                        padding: 20px;
                        z-index: 2;
                        opacity: 1;
                        height: 225px!important;
                        transition: all $duration ease-in-out;
                    }

                    .ff-img-holder + .ff-content {
                        opacity: 0;
                    }
                }

                .ff-item-cont::before {
                    content: "";
                    position: absolute;
                    z-index: 1!important;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: $color-dark;
                    z-index: 0;
                    opacity: 0;
                    transition: all $duration ease-in-out;
                  }

                .ff-item-bar {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    opacity: 0;
                    border-top: 0!important;
                    transition: all $duration ease-in-out;

                    & a, & .ff-icon-share {
                        color: $color-white!important;

                        &:hover, &:focus {
                            color: $color-fourth!important;
                        }
                    }
                }

                .ff-share-popup {
                    right: -15px!important;
                    background-color: $color-main!important;

                    & a:hover {
                        color: $color-text!important;
                    }

                    &::after {
                        right: 15px;
                        background-color: $color-main!important;
                    }
                }

                &:hover, &:focus, &:focus-within {
                    .ff-item-cont {
                        .ff-content {
                            opacity: 1;
                        }
                        &:before {
                            opacity: 1;
                        }
                    }

                    .ff-item-bar {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Retour en image
//======================================================================================================


.retour {
    margin: 150px 0 119px;

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 685px;
            height: 690px;
            left: -395px;
            bottom: -515px;
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_images.svg);
            background-repeat: no-repeat;
            z-index: -1;
            background-size: 685px;
        }
    }

    &__title {
        @extend %homeTitle;
    }

    &__description {
        @extend %homeDescription;
        margin-top: -3px;
    }

    &__blocs {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }

    &__bloc {
        width: 400px;
        height: 280px;

        &:nth-child(3), &:nth-child(4), &:nth-child(5) {
            width: 310px;
            height: 210px;
        }

        &:nth-child(2) {
            margin-top: -115px;
        }

        &:nth-child(3) {
            margin-top: -45px;
        }

        &:nth-child(4) {
            margin-left: 90px;
        }

        &:nth-child(5) {
            margin-top: -115px;
        }

        &:nth-child(6) {
            margin-top: -115px;
        }

        &:hover, &:focus {
            .retour__content {
                opacity: 1;

                svg {
                    transform: scale(1);
                }
            }

            .retour__image--video {
                svg {
                    opacity: 0;
                }
            }
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
    }

    &__image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }

        &--video {
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                transition: all $duration ease-in;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        opacity: 0;
        transition: all $duration ease-in-out;

        svg {
            width: 40px;
            height: 40px;
            z-index: 2;
            transform: scale(0);
            transition: all $duration ease-in-out;
        }

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(17, 17, 17, 0.7), rgba(17, 17, 17, 0.7));
        } 

        &--video {
            cursor: pointer;
        }
    }

    &__title--small {
        font-weight: 400;
        font-size: toRem(16);
        color: $color-white;
        width: 250px;
        text-align: center;
        z-index: 1;
    }

    &__button {
        width: 310px;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
    }

    .popup-area {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        background: $color-black--rgba;

        &.show-popup {
            visibility: visible;
            opacity: 1;
        }

        .accordion {
            height: 0;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        .close-area-popup {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: calc((100% - 1170px)/2);
            width: 40px;
            height: 40px;
            background: $color-white;
            border-radius: 50px;

            &::before {
                content: '\f335';
                font: normal 30px/1 'dashicons';
            }
        }
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        .slideshow {
            height: 380px;

            &__container .ic-plus {
                left: calc(50% + 430px);
            }

            &__svg {
                width: 630px;
                height: 135px;
            }
        }
    }

    .news {
        &__container {
            &:before {
                width: 790px;
                top: 10px;
                background-size: 790px;
            }
        }
    
        &__swiper {
            width: 940px;
        }

        &__item {
            &--small {
                width: 300px!important;
            }
        }
    
        &__info {
            max-width: 290px;
            margin-bottom: 5px;
        }
    
        &__titlep {
            &--big {
                margin: 16px 0 10px;
            }
    
            &--small {
                max-width: 300px;
            }
        }

        &__introduction {
            font-size: toRem(14);
            line-height: 1.25rem;
        }
    
        &__image {
            &--big {
                width: 620px;
                height: 320px;
            }
    
            &--small {
                width: 300px;
                height: 145px;
            }
        }
    
        .notimg {
            &--big {
                width: 620px;
                height: 320px;
            }
    
            &--small {
                width: 300px;
                height: 145px;
            }
        }

        &__bottom {
            margin-top: 42px;
        }

        &__navigation {
            gap: 340px;
        }
    }

    .home-events {
        &__container {
            &:before {
                width: 590px;
                height: 595px;
                right: -450px;
                top: -222px;
                background-size: 590px;
            }
        }

        &__swiper {
            margin: -10px !important;
            padding: 10px !important;
        }
    
        &__link {
            width: 220px!important;
            margin-right: 20px;
            min-height: 260px;
            padding: 35px 15px 45px;
            box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.08);

            &:before {
                top: 30px;
                left: 15px;
            }
        }
    
        &__title--small {
            height: 75px;
            font-size: toRem(20);
            margin: 18px 0 36px;
        }
    
        &__date {
            font-size: toRem(18);
        }
    
        &__bottom {
            margin-top: 37px;
        }
    
        &__buttons {
            gap: 20px;
        }
    
        &__navigation {
            gap: 600px;
        }
    }

    .social {
        &__container {
            &:before {
                left: -415px;
            }
    
            &:after {
                top: -275px;
                right: -455px;
            }
        }

        &__content {
            margin-left: -95px;
        }

        &__title {
            margin-top: -5px;
        }
    
        &__description {
            margin-top: -5px;
        }
    
        &__intro {
            font-size: toRem(14);
            margin: 15px 0 20px;
        }
        
        &__wall {
            z-index: 9;
            width: 780px;
            height: 220px;
        }
    
        &__buttons {
            gap: 10px;
        }
    
        #ff-stream-1 {
             height: 220px;
             min-height: unset!important;
            .ff-stream-wrapper {
                // width: 750px;
                 height: 220px;
                 margin: 0 0 0 -70px !important;
                 width: 780px;
    
                .ff-item {
                    width: 200px!important;
                    height: 220px!important;
    
                    .ff-item-cont {
                        height: 220px!important;
    
                        .ff-img-holder {
                            height: 220px!important;
                        }
    
                        .ff-content {
                            padding: 15px;
                            height: 200px!important;
                            transition: all $duration ease-in-out;
                        }
                    }
                }

                #ff-uid-2 {
                    transform: translateX(290px)!important;
                }

                #ff-uid-3 {
                    transform: translateX(510px)!important;
                }
            }
        }
    }

    .retour {
        &__container {
            &:before {
                background-size: 555px;
                bottom: -430px;
                height: 550px;
                left: -385px;
                width: 555px;
            }
        }
    
        &__description {
            margin-top: -5px;
        }
    
        &__blocs {
            gap: 20px;
        }
    
        &__bloc {
            width: 325px;
            height: 225px;
    
            &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                width: 250px;
                height: 170px;
            }
    
            &:nth-child(2) {
                margin-top: -85px;
            }
    
            &:nth-child(3) {
                margin-top: -30px;
            }
    
            &:nth-child(4) {
                margin-left: 70px;
            }
    
            &:nth-child(5) {
                margin-top: -85px;
                margin-left: 5px;
            }
    
            &:nth-child(6) {
                margin-top: -85px;
            }
        }
    
        &__title--small {
            width: 200px;
        }
    
        &__button {
            width: 250px;
            bottom: 21px;
            margin-left: -5px;
        }

        .popup-area .close-area-popup {
            right: calc(50% - 470px);
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        position: relative;

        .slideshow {
            height: 300px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__container .ic-plus {
                left: calc(50% + 270px);
                bottom: 20px;
            }

            &__svg {
                width: 450px;
                height: 95px;
                top: 95px;
                transform: translate(-50%,0);
            }

            &__pagination {
                bottom: 20px;
            }
        }
    }

    .access {
        padding: 60px 0 115px;
    
        &__blocs {
            &:before {
                top: -65px;
                right: -455px;
            }
        }
    
        &__bloc {
            width: 90px;
            height: 96px;
            display: flex;
            font-size: toRem(13);
            line-height: 18px;
    
            svg {
                width: 50px;
                height: 50px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 20px;
                height: 20px;
                border-radius: 50%;
                z-index: -1;
                transition: all $duration ease-in-out;
            }
    
            &:nth-child(1) {
                &:before {
                    left: 35px;
                }
            }
    
            &:nth-child(2) {
                &:before {
                    top: 30px;
                }
            }
    
            &:nth-child(3) {
                &:before {
                    left: 35px;
                    top: 30px;
                }
            }
    
            &:nth-child(4) {
                &:before {
                    left: -8px;
                }
            }
    
            &:nth-child(5) {
                &:before {
                    left: 20px;
                    top: -8px;
                }
            }
    
            &:nth-child(6) {
                &:before {
                    left: 38px;
                    top: 21px;
                }
            }
        }
    }

    .news {
        &__container {
            &:before {
                width: 560px;
                height: 500px;
                left: -160px;
                top: -55px;
                background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_mobile/bg_actus_mobile.svg);
                background-size: 560px;
            }

            &:after {
                width: 250px;
                height: 285px;
                background-size: 250px;
                background-color: $color-third;
            }
        }
    
        &__swiper {
            width: 790px;
            max-width: unset;
        }

        &__info {
            max-width: 300px;
            margin-bottom: 0;
        }

        &__titlep {
            &--big {
                line-height: toRem(25);
                margin: 25px 0 10px;
            }
        }
    
        &__image {
            &--big {
                width: 320px;
                height: 200px;
                margin-right: -10px;
            }
    
            &--small {
                height: 150px;
            }
        }
    
        .notimg {
            &--big {
                width: 320px;
                height: 200px;
                margin-right: -10px;
            }
    
            &--small {
                height: 150px;
            }
        }

        &__bottom {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &__navigation {
            gap: 10px;
        }

        &__button {
            position: relative;
        }
    }

    .home-events {
        &__container {
            &:before {
                background-size: 305px;
                height: 305px;
                right: -160px;
                top: -60px;
                width: 305px;
                background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_mobile/bg_agenda_mobile.svg);
            }
        }

        &__header {
            margin-bottom: 25px;
        }

        &__swiper {
            margin: -10px !important;
            padding: 10px !important;
            width: 800px;
            max-width: unset;
        }
    
        &__link {
            width: 300px!important;
            min-height: 305px;
            padding: 52px 30px 45px;

            &:before {
                left: 30px;
                top: 45px;
            }
        }

        &__title--small {
            font-size: toRem(25);
            margin: 38px 0 42px;
            max-width: 210px;
        }

        &__date {
            font-size: toRem(22);
        }
    
        &__bottom {
            margin-top: 15px;
            flex-direction: column;
            gap: 20px;
        }

        &__navigation {
            gap: 10px;
        }
    
        &__buttons {
            gap: 20px;
            position: relative;
        }
        
        &__button {
            width: 300px
        }
    }

    .social {
        margin-top: 140px;

        &__container {
            flex-direction: column-reverse;

            &:before {
                top: -78px;
                left: -305px;
                width: 615px;
                mask-size: 615px;
                background-color: $color-gray;
            }
    
            &:after {
                height: 335px;
                mask-size: 375px;
                right: -285px;
                top: unset;
                width: 375px;
                bottom: -372px;
                background-color: $color-third;
            }
        }

        &__content {
            margin-left: 0;

            &--bottom {
                position: absolute;
                bottom: -60px;
                color: $color-dark;
                display: flex;
                align-items: flex-start;
                gap: 20px;
            }
        }

        &__title {
            margin-top:0;
        }

        &__intro {
            margin: 0;
            max-width: 300px;
        }
        
        &__wall {
            width: 640px;
            height: 300px;
            margin-top: 23px;
        }
    
        &__buttons {
            gap: 15px;
        }

        &__button {
            background-color: $color-main;

            svg {
                fill: $color-white;
            }

            &:hover, &:focus {
                svg {
                    fill: $color-dark;
                }
            }
        }
    
        #ff-stream-1 {
            height: 300px;
            min-height: unset!important;
            .ff-stream-wrapper {
                height: 300px;
                margin: 0 !important;
                width: 780px;
    
                .ff-item {
                    width: 300px!important;
                    height: 300px!important;
    
                    .ff-item-cont {
                        height: 300px!important;
    
                        .ff-img-holder {
                            height: 300px!important;
                        }
    
                        .ff-content {
                            padding: 30px;
                            height: 180px!important;
                        }
                    }

                    .ff-item-bar {
                        padding: 10px 10%;
                    }
                }

                #ff-uid-2 {
                    transform: translateX(320px) !important;
                }

                #ff-uid-3 {
                    display: none;
                }
            }
        }
    }

    .retour {
        margin: 137px 0 92px;

        &__container {
            &:before {
                background-size: 390px;
                height: 270px;
                left: -280px;
                width: 390px;
                bottom: -85px;
            }
        }
    
        &__blocs {
            gap: 20px;
        }
    
        &__bloc {
            width: 300px;
            height: 200px;
            margin: 0!important;
    
            &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                width: 300px;
                height: 200px;
            }
        }
    
        &__button {
            width: 300px;
            position: relative;
            bottom: unset;
            margin-top: 30px;
            margin-left: 0;
        }

        .popup-area .close-area-popup {
            right: calc(50% - 310px);
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .home-slideshow {
        .slideshow {
            height: 200px;

            &__container .ic-plus {
                left: calc(50% + 120px);
                bottom: 15px;
                width: 30px;
                height: 30px;
            }

            &__svg {
                width: 300px;
                height: 65px;
                top: 60px;
            }

            &__pagination {
                bottom: 15px;
            }
        }
    }

    .access {
        &__blocs {
            flex-wrap: wrap;
            gap: 27px 0px;
        }
    }

    .news {
        &__container {
            display: flex;
            flex-direction: column;
        }

        &__content {
            gap: 30px;
        }

        &__item {
            &--big {
                flex-direction: column;
                gap: 20px;
                align-items: unset;
                justify-content: unset;
                width: fit-content;
            }
        }

        &__image {
            &--big {
                margin-right: 0;
                margin-left: -10px;
            }
        }
    
        .notimg {
            &--big {
                margin-right: 0;
                margin-left: -10px;
            }
        }

        &__swiper {
            width: 470px;
        }

        &__titlep {
            &--big {
                margin: 28px 0 10px;
            }
        }
    }

    .home-events {
        margin-top: 53px;

        &__container {
            &:before {
                right: -220px;
                top: -55px;
            }
        }

        &__buttons {
            flex-direction: column-reverse;
            gap: 10px;
        }
    }

    .social {
        &__container {
            &:after {
                bottom: -415px;
            }
        }

        &__content {
            &--bottom {
                bottom: -123px;
                flex-direction: column;
            }
        }
        
        &__wall {
            width: 640px;
            height: 300px;
            margin-top: 23px;
        }
    
        #ff-stream-1 {
            .ff-stream-wrapper {
                #ff-uid-2 {
                    display: none;
                }
            }
        }
    }

    .retour {
        margin: 178px 0 92px;

        &__bloc {
            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                display: none;
            }
        }

        .popup-area .close-area-popup {
            right: calc(50% - 150px);
        }
    }
}   
