.breadcrumb {
    margin: 40px 0 0;
    
    ul  {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 30px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        position: relative;
        font-weight: 400;
        font-size: toRem(16);

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/base-icons/ic-nav-chevron-bottom.svg);
                background-color: $color-dark;
                mask-repeat: no-repeat;
                top: unset;
                right: -25px;
                width: 22px;
                height: 22px;
                -webkit-mask-size: 22px;
                mask-size: 22px;
                transform: rotate(-90deg);
            }
        }

        &:first-child {
            padding-left: 0;
        }

        a {
            transition: all $duration ease-in-out;

            &:hover {
                color: $color-main;
                text-decoration: underline;
            }
        }
    }

}

