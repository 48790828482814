.nav-main {
    margin-left: -25px;
    z-index: 2;

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }

    // Main menu
    .menu {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;

        &__item {

            &.menu-item-has-children {
                &:hover {
                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            &:nth-child(4) {
                .menu__link {
                    margin-left: 270px;
                }
            }
    
            &:last-child {
                .menu__link {
                    &:before {
                        position: absolute;
                        content: "";
                        width: 50px;
                        height: 1px;
                        background-color: $color-dark;
                        bottom: 30px;
                        right: 25px;
                        transition: all $duration ease-in-out;
                    }
                }
            }

            &:hover {
                .menu__link {
                    color: $color-white;
                    
                    &:before {
                        width: calc(100% - 50px);
                        background-color: $color-white;
                    }

                    &:after {
                        height: 100%;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            display: flex;
            align-items: center;
            height: 95px;
            padding: 0 25px;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family;
            font-weight: 700;
            font-size: toRem(16);
            gap: 5px;
            letter-spacing: 0.05em;
            transition: all $duration ease-in-out;

            span {
                font-weight: 300;
            } 

            &:after {
                position: absolute;
                content: "";
                width: 100%;
                height: 0;
                background-color: $color-main;
                top: 0;
                right: 0;
                z-index: -1;
                transition: all $duration ease-in-out;
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        right: 0;
        z-index: 1;
        width: 100%;
        padding: 30px 15px;
        background: $color-main;
        box-shadow: $shadow;

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            height: 60px;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 30px 15px 0;
            color: $color-white;
            font-weight: 700;
            font-size: toRem(16);
            transition: all $duration ease-in-out;

            svg {
                transform: rotate(-90deg);
                fill: $color-white;
                width: 30px;
                min-width: 30px;
                height: 30px;
            }

            &:before {
                position: absolute;
                content: "";
                height: 40px;
                width: 40px;
                left: -3px;
                top: 10px;
                background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/Pictos/fond_ic_plus.svg);
                background-repeat: no-repeat;
                z-index: 0;
                transform: scale(0);
                transition: all $duration ease-in-out;
            }    

            &:hover {
                padding-left: 5px;
                gap: 12px;

                &:before {
                    transform: scale(1);
                }

                svg {
                    fill: $color-main;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close, .submenu__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            &__item {
                &:first-child {
                    .menu__link {
                        margin-left: 32px;
                    }
                }

                &:nth-child(4) {
                    .menu__link {
                        margin-left: 175px;
                    }
                }

                &:last-child {
                    .menu__link {
                        margin-left: -5px;

                        &:before {
                            right: 20px;
                        }
                        
                        &:hover .menu__link::before {
                            width: calc(100% - 40px);
                        }
                    }
                }
            }

            &__link {
                font-size: toRem(14);
                padding: 0 17px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-active-navmain {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-active-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 20px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-white;
            padding: 40px 0;
            transition: $duration;

            &:after {
                position: absolute;
                content: "";
                width: 460px;
                height: 380px;
                left: -120px;
                top: 515px;
                mask-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_rs_1.svg);
                background-color: $color-fourth;
                mask-repeat: no-repeat;
                z-index: -1;
                mask-size: 460px;
            }
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 9px;
            min-height: 95px;
            margin: 0 0 0 25px;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-family: $font-family;
            font-weight: 600;
            font-size: toRem(16);
            color: $color-dark;
            text-transform: uppercase;
            transition: $duration;
            cursor: pointer;
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 20px;
            height: 2px;
            background: $color-dark;
            transition: $duration;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $color-dark;
                transition: $duration;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            } 

            &:hover {
                color: $color-dark;
            }
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            min-height: 35px;
            margin: -10px 35px 0 615px;
            padding: 10px;
            background-color: transparent;
            border: 1px solid $color-dark;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 15px;
                height: 15px;
                margin: 0;
                fill: $color-dark;
                transition: all $duration ease-in-out;
            }

            &.submenu-open {
                border: 1px solid $color-white;

                svg {
                    fill: $color-white;
                }
            }

            &:hover,
            &:focus {
                background-color: $color-dark;
                border-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 620px;
            padding: 0;
            gap: 21px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-dark;
                        transition: all $duration;
                    }
    
                    &:focus-within .submenu, 
                    &:hover .submenu {
                        justify-content: flex-start;
                    }
    
                    // If sub menu open
                    &.js-active-submenu {   
                        .submenu {
                            right: 0;
                        }
                    }
                }

                &:last-child {
                    .menu__link {
                        &:before {
                            right: unset;
                            bottom: 9px;
                            left: 92px;
                        }
    
                        &:hover {
                            &:before {
                                background-color: $color-dark;
                                width: 50px;
                            }
                        }
                    }

                    &:after {
                        display: flex;
                    }
                }
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
    
                    .menu__link {
                        color: $color-dark;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 15px 0;
                color: $color-dark;
                font-size: toRem(16);
                margin-left: 0!important;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-dark;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 35px;
            width: 100vw;
            height: 100vh;
            padding: 40px 0 60px 0;
            background-color: $color-main;
            transition: $duration;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 35px;
                height: 35px;
                margin: -10px 0 0 0;
                padding: 0;
                position: relative;
                left: calc(50% + -310px);
                background-color: transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }

                svg {
                    width: 35px;
                    height: 35px;
                    fill: none;
                    fill: $color-white;
                    transform: rotate(90deg);
                    transition: $duration;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 620px;
                padding: 0;
                overflow-y: auto;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;

                &:before {
                    left: 0;
                }

                &::after {
                    display: none;
                }

                &:hover {
                    padding-left: 8px;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .nav-main {
        &__close {
            margin: -10px 40px 0 300px;
        }

        &__button {
            min-height: 70px;
        }

        .menu {
            width: 300px;
        }

        .submenu {
            &__nav {
                max-width: 300px;
            }

            &__close {
                left: calc(50% - 150px);
            }
        }
    }
}