.scrollTopBtn {
    width: 45px;
    height: 45px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    transform: translateY(100px);
    z-index: 100;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: all .5s ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        path {
            fill: $color-white;
            transition: $duration;
        }

        &:last-child {
            width: 39px;
            height: 39px;
            position: absolute;
            top: 3px;
            left: 0;
            fill: $color-main;
            z-index: -1;
        }
    }

    &:before {
        position: absolute;
        content: "";
        width: 41px;
        height: 41px;
        right: 5px;
        top: 2px;
        background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/Pictos/fond_ic_plus.svg);
        background-repeat: no-repeat;
        background-size: 41px;
        z-index: -1;
    }

    &:hover,
    &:focus {
        svg {
            fill: $color-white;

            path {
                fill: $color-white;
            }
            
            &:last-child {
                fill: $color-second;
            }
        }
    }
}