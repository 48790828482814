.cover {
    &__image-wrapper {
        width: 100%;
        height: 460px;
        background: $color-bg--image;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(0deg, rgba(17, 17, 17, 0.3), rgba(17, 17, 17, 0.3));
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: "";
            width: 700px;
            height: 150px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/ville_de_linas.svg);
            background-repeat: no-repeat;
            z-index: 2;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        padding: 20px 0 50px;

        .container {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 490px;
                height: 500px;
                right: -405px;
                bottom: -468px;
                background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_top_post.svg);
                background-repeat: no-repeat;
                z-index: -1;
                background-size: 490px;
            }
        }
    }

    &__title {
        font-weight: 700;
        font-size: toRem(50);
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 10px 0 0;
            font-weight: $font-weight-semibold;
            font-size: toRem(18);
        }
    }

    &__intro {
        color: $color-text;
    }

    &__date {
        color: $color-main;

        svg {
            fill: $color-main;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 380px;

            &:after {
                width: 630px;
                height: 135px;
                background-size: 630px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 300px;

            &:after {
                width: 450px;
                height: 95px;
                top: 95px;
                transform: translate(-50%,0);
                background-size: 450px;
            }
        }

        &__title {
            font-size: toRem(38);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            height: 200px;

            &:after {
                width: 300px;
                height: 65px;
                top: 60px;
                background-size: 300px;
            }
        }

        &__title {
            font-size: toRem(32);
        }

        &__lead-wrapper p {
            font-size: toRem(18);
        }
    }

}
