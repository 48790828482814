.footer {
    margin-top: 50px;
    
    &__infos {
        display: flex;
    }

    &__contact {
        display: flex;
        gap: 20px;
        position: relative;
        margin-left: 55px;
        margin-top: 66px;

        &:before {
            position: absolute;
            content: " ";
            width: 25px;
            height: 25px;
            left: 45px;
            top: -15px;
            border-radius: 50%;
            background-color: $color-third;
            z-index: -1;
            transition: all $duration ease-in-out;
        }

        svg {
            width: 60px;
            height: 40px;
        }

        p {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            font-size: toRem(16);
            text-transform: uppercase;
            font-style: italic;

            span {
                font-weight: 400;
                margin-top: -5px;
                font-style: normal;
            }
        }

        &:hover, &:focus {
            &:before {
                transform: scale(1.5);
            }
        }
    }

    &__coordonnees {
        margin-left: 167px;
        display: flex;
        flex-direction: column;

        .footer__text {
            position: relative;
            color: $color-dark;

            svg {
                width: 20px;
                height: 20px;
                position: absolute;
                bottom: 2px;
                right: 78px;
                fill: $color-dark;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-main;
                
                svg {
                    fill: $color-main;
                }
            }
        }
    }

    &__horaires {
        width: 430px;
        margin-left: 163px;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: 500;
        font-size: toRem(20);
    }

    &__text {
        font-weight: 400;
        font-size: toRem(16);
        margin-top: 10px;
    }

    &__phone {
        font-weight: 700;
        font-size: toRem(16);
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            color: $color-main;
        }
    }

    &__partenaires {
        display: flex;
        justify-content: space-between;
        margin: 98px auto 15px;
    }

    &__left {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 420px;
            height: 110px;
            left: -100px;
            bottom: -15px;
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_label.svg);
            background-repeat: no-repeat;
            z-index: -1;
            background-size: 420px;
        }

        svg {
            width: 95px;
            height: 50px;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            svg {
                transform: scale(1.075);
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 30px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 915px;
            height: 176px;
            right: -425px;
            bottom: -15px;
            background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_desktop/bg_partenaires.svg);
            background-repeat: no-repeat;
            z-index: -1;
            background-size: 915px;
        }
    }

    &__partenaire {
        transition: all $duration ease-in-out;

        &:nth-child(1) {
            svg {
                width: 130px;
                height: 30px;
                fill: $color-white;
            }
        }

        &:nth-child(2) {
            svg {
                width: 100px;
                height: 35px;
                fill: $color-white;
            }
        }

        &:nth-child(3) {
            img {
                width: 74px;
                height: 35px;
            }
        }

        &:hover, &:focus {
            transform: scale(1.075);
        }
    }

    &__menu {
        padding: 20px 0;
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.08);

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        li {
            position: relative;

            a {
                font-weight: 400;
                font-size: toRem(14);
                text-decoration: none;
                transition: all $duration ease-in-out;
                
                &:hover, &:focus {
                    color: $color-main;
                    text-decoration: underline;
                }
            } 
        }
    }
}

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__contact {
            margin-left: 0;
            margin-top: 50px;
        }

        &__coordonnees {
            margin-left: 133px;
            width: 195px;

            .footer__text svg {
                right: 75px;
            }
        }

        &__horaires {
            margin-left: 88px;
        }

        &__text {
            margin-top: 5px;
            font-size: toRem(14);
        }

        &__phone {
            font-size: toRem(14);
        }

        &__partenaires {
            margin: 112px auto 15px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__infos {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__contact {
            margin: 0;
            margin-left: -35px;
        }

        &__coordonnees {
            margin-left: 0;
            width: 195px;
            text-align: center;
            margin-top: 25px;

            .footer__text svg {
                right: 32px;
            }
        }

        &__horaires {
            margin-left: 0;
            text-align: center;
            margin-top: 27px;
            max-width: 300px;
        }

        &__text {
            font-size: toRem(16);
            margin-top: 10px;
        }

        &__phone {
            font-size: toRem(16);
        }

        &__partenaires {
            margin: 103px auto 26px;
            justify-content: center;
            gap: 25px;
        }

        &__left {
            &:before {
                display: none;
            }
    
            svg {
                width: 53.2px;
                height: 28px;
            }
        }
    
        &__right {
            gap: 15px;
    
            &:before {
                width: 505px;
                height: 130px;
                right: -103px;
                bottom: -25px;
                background-image: url(/wp-content/themes/linas/assets/src/images/sprite-images/home/bg_mobile/bg_partenaires_mobile.svg);
                background-size: 505px;
            }
        }
    
        &__partenaire {
            &:nth-child(1) {
                svg {
                    width: 77.4px;
                    height: 18px;
                }
            }
    
            &:nth-child(2) {
                svg {
                    width: 66.7px;
                    height: 23px;
                }
            }
    
            &:nth-child(3) {
                img {
                    width: 48.3px;
                    height: 23px;
                }
            }
        }

        &__menu {
            ul {
                gap: 25px 50px;
                justify-content: center;
                flex-wrap: wrap;
            }
    
            li {
                font-size: toRem(14);
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__menu ul {
            gap: 30px 50px;
        }
    }
}