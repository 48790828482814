//=================================================================================================
// Filter
//=================================================================================================
.filter {
    &__container {
        @extend %container;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__options {
        display: none;
        margin-top: 20px;

        input {
            padding: .75rem;
            color: $color-white;
            border: none;
            background-color: $color-main;
        }
    }

    &__select-wrapper.is-open input.filter__input.selected~.filter__input-svg {
        fill: $color-white;
    }


    &__input-container {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    input.filter__input {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::placeholder {
            color: $color-white;
            -webkit-text-fill-color: $color-white;
            opacity: 1; // required on iOS
        }

        &#keywordsFilter {
            padding-right: 35px;

            &::placeholder {
                color: $color-white;
            }
        }
        
        // all
        &#keywordsFilter, &#subCategoriesInput, 
        // Documents
        &#monthsReleaseInput, &#yearsReleaseInput, 
        // Search
        &#postTypeInput {
            padding-right: 45px;
        }

        &:not(#keywordsFilter):not(#mapKeywordsFilter):not(#startDateFilter):not(#endDateFilter) {
            pointer-events: none;
        }

        &.selected {
            color: $color-white;
            -webkit-text-fill-color: $color-white;
            opacity: 1; // required on iOS
            border-color: $color-main !important;

            & ~ .filter__input-svg {
                fill: $color-white;
            }

            & ~ .filter__input-close {
                opacity: 1;
                pointer-events: inherit;
            }

            & ~ .filter__input-search {
                opacity: 1;
            }
        }

        &:focus {
            border-color: $color-main !important;
        }
    }

    &__input-svg {
        position: absolute;
        right: 10px;
        width: 30px;
        height: 30px;
        fill: $color-white;
        transition: fill $duration ease-in-out;
    }

    &__input-close {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 10px;
        margin: 0;
        padding: 0;
        background: $color-main;
        border: 1px solid $color-main;
        border-radius: $btn-spe-border-radius;
        transform: translateY(-50%);
        transition: all $duration;


        &:hover, &:focus {
            background: transparent;

            svg {
                fill: $color-fourth;
            }
        }
        
        svg {
            width: 26px;
            height: 26px;
            fill: $color-white;
            transition: fill $duration ease-in-out;
        }
    }

    &__input-search {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 5px;
        width: 22px;
        height: 22px;
        padding: 0;
        margin: 0;
        background: $color-main;
        border: 1px solid $color-main;
        border-radius: $btn-spe-border-radius;
        transform: translateY(-50%);
        transition: all $duration;

        &:hover, &:focus {
            background: $color-bg--transparent;

            &::before {
                color: $color-main;
            }
        }

        &::before {
            content: "\f179";
            color: $color-white;
            font: normal 18px/1 dashicons;
        }

    }

    &__select-wrapper {
        padding-bottom: 0;
        position: relative;
        z-index: 1;
        color: $color-gray;
        font-size: $btn-font-size;

        &.is-closed {
            .filter-container {
                visibility: hidden;
                opacity: 0;
                height: 0;
                padding: 0;
            }
        }

        &.is-open {
            box-shadow: $shadow;
            z-index: 2;

            .filter__input-container {
                background: $color-white;
                z-index: 2;

                .filter__input {
                    color: $color-white;
                    border-color: $border-color;
                }

                .filter__input-svg {
                    fill: $color-white;
                }
            }

            .filter-container {
                visibility: visible;
                opacity: 1;
            }

            input.filter__input::placeholder {
                color: $color-text;
            }
        }

        &.date-picker {
            .filter__input-svg {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .filter-container {
        overflow: auto;
        position: absolute;
        width: 100%;
        max-height: 290px;
        padding: 10px 10px 0px;
        background: $color-bg--neutral;
        box-shadow: $shadow;

        ul {
            li {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: 0;
                margin-bottom: 10px;
                border-radius: $border-radius;

                &[data-level="2"] {
                    label::before {
                        content: "-";
                    }
                }
                &[data-level="3"] {
                    label::before {
                        content: "- -";
                    }
                }
                &[data-level="4"] {
                    label::before {
                        content: "- - -";
                    }
                }

                label {
                    width: 100%;
                    cursor: pointer;
                    margin: 0;
                    padding: 10px;
                    background-color: $color-white;
                    font-weight: $font-weight;
                    transition: all $duration;
                    color: $color-dark;

                    &:hover, &:focus {
                        background-color: $color-main;
                        color: $color-white;
                    }
                }

                input {
                    position: absolute;
                    right: 25px;

                    &:checked {
                        &~label {
                            color: $color-white;
                            background-color: $color-main;
                        }
                    }
                }

                .filter__date-radio, .filter__input-radio {
                    display: none;
                }
            }
        }
    }

    &__indicator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0 10px 0;
    }

    &__all-select {
        margin: 0;
        height: auto; // si height existante
        min-height: 40px;
        margin: 0;
        padding: 8px 15px; // Eventuellement le padding à changer si besoin
    }

    .empty-selection {
        border-color: $color-gray;
        color: $color-gray;
        pointer-events: none;
    }

    // ----------------------------------------------------------------------------------------------------
    // FilterChange Update filter 05/23 -- https://gitlab.creasrv.net:8080/creasit-v2/themes/noyau/-/issues/107
    // ----------------------------------------------------------------------------------------------------

    &__item[class*=col-] {
        padding-bottom: 20px;
    }

    &__action[class*=col-] {
        padding-bottom: 0;
    }

    // Title + Toggler button
    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--5;
        font-weight: $font-weight--heading;
        text-transform: uppercase;
        // -----------------------------------------
        // FilterChange A changer selon le fond
        color: $color-text;
    }
    &__icon {
        display: flex;
        align-items: center;
        margin: 0 0 0 10px;
        background-color: $btn-toggler-bg;
        border: 1px $btn-border-style $btn-toggler-border-color;
        border-radius: $border-radius--round;
        transition: all $duration ease-in-out;

        svg {
            width: 20px;
            height: 20px;
            fill: $btn-toggler-color;
            transition: fill $duration ease-in-out;
        }
    }
    &__toggler {
        display: flex;
        align-items: center;
        transition: all $duration ease-in-out;

        &.is-open {
            .filter__icon {
                transform: rotate(-180deg);
            }
        }

        &:hover, &:focus, &:active {
            .filter__icon {
                background-color: $btn-toggler-bg--hover;
                border-color: $btn-toggler-border-color--hover;
        
                svg {
                    fill: $btn-toggler-color--hover;
                }
            }
        }
    }

    #sub-categories[data-disabled="disabled"] .select-box {
        opacity: 0.35;
        pointer-events: none;
    }
}


//=================================================================================================
// Filter maps
//=================================================================================================
#filters-maps {
    &.load {
        .filter {
            &__input-container {
                &.maps-term {
                    pointer-events: none;

                    label,
                    input {
                        pointer-events: none;
                    }
                }
            }

            &__term-container {
                li {
                    pointer-events: none;

                    label,
                    input {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .filter {
        &__select-wrapper {

            &.is-open {
                box-shadow: none;

                .filter__input-container.maps-term {
                    .filter__input-svg {
                        transform: rotate(180deg);
                    }

                    label {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            &.selected {
                .filter-container {
                    background: $color-main;
                }
            }
        }

        &__input-container {
            &.maps-term {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                margin-bottom: 10px;
                border-radius: $border-radius;

                label {
                    padding: 14px 10px 14px 45px;
                    cursor: pointer;
                    pointer-events: auto;
                    width: 100%;
                    margin: 0;
                    background: $color-white;
                    border-radius: $border-radius;
                    color: $color-text;
                    font-weight: $font-weight;
                    transition: all $duration;

                    &:hover {
                        background: $color-main;
                        color: $color-white;

                        svg {
                            fill: $color-white;
                        }
                    }

                    svg {
                        position: absolute;
                        top: 2px;
                        left: 3px;
                        width: 40px;
                        height: 40px;
                        fill: $color-main;
                        transition: all $duration;
                    }
                }

                input[type=checkbox] {
                    visibility: hidden;
                    position: absolute;

                    &:checked {
                        ~ label {
                            background: $color-main;
                            color: $color-white;
                            
                            svg {
                                fill: $color-white;
                            }
                        }
                    }
                }

                .filter__input-svg {
                    right: 0;
                    background: $color-bg--neutral;
                    margin-right: 10px;
                    border-radius: 50%;
                    fill: $color-main;
                }
            }
        }

        .filter-container {
            position: relative;
            top: -13px;
            background: $color-white;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            box-shadow: none;

            ul {
                li {
                    background: $color-bg--neutral;
                }
            }
        }

        #mapKeywordsFilter {
            padding: 15px 12px;
            margin-bottom: 20px;
        }

        .filter__input-search {
            width: 25px;
            height: 25px;
            transition: 0s;

            &::before {
                content: 'Aucun résultat';
                display: none;
            }

            &:hover,
            &:focus,
            &:visited,
            &:active {
                svg {
                    fill: $color-main;
                }
            }

            svg {
                position: relative;
                right: 0;
                width: 11px;
                height: 11px;
                fill: $color-white;
                transition: 0s;

                &.svg-close {
                    display: none;
                }
            }

            &.reset-filter {
                svg {
                    &.svg-search {
                        display: none;
                    }
                    &.svg-close {
                        display: block;
                    }
                }
            }
        }

        .no-result {
            .filter__input-search {
                border: 0;

                &:hover,
                &:focus,
                &:visited,
                &:active {
                    &::before {
                        background: $color-bg--transparent;
                        color: $color-main;
                    }
                }

                &::before {
                    display: flex;
                    position: absolute;
                    right: -2px;
                    flex: 1 0 0;
                    height: 27px;
                    min-width: 130px;
                    padding: 5px 10px;
                    background: $color-main;
                    border: 1px solid $color-main;
                    border-radius: $btn-spe-border-radius;
                    font-size: $font-size--text-small;
                    font-family: $font-family;
                    text-transform: none;
                    line-height: $line-height--heading;
                    transition: 0s;
                }
            }
        }
    }

    #childrenTerms {
        label  {
            color: $color-text;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .filter__all-select {
        margin-top: 5px;
    }

}